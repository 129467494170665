/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

type UtilRequiredKeys<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export interface ActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
}

export type ActionLogViewObject1 = ActionLogViewObject & {
  log?: TLog | null;
};

export interface AddOrUpdateAllowableIpsRequest {
  /** @format uuid */
  userId: string;
  allowableIps: string[];
}

export interface AddOrUpdateCartRequest {
  lineItems: LineItem[];
  receiverAddress: Address;
  description?: string | null;
  requisition?: string | null;
}

export interface AddOrUpdateCustomerAddressRequest {
  /** @format uuid */
  customerId: string;
  /** @format uuid */
  addressId: string;
  address: Address;
}

export interface AddOrUpdateCustomerContactsRequest {
  /** @format uuid */
  customerId: string;
  contacts: CustomerContact[];
}

export interface AddOrUpdateCustomerNoteRequest {
  /** @format int32 */
  noteId?: number | null;
  /** @format uuid */
  customerId: string;
  note: string;
  /** @format date-time */
  date: string;
}

export interface AddOrUpdateDictionaryLanguageRequest {
  /** @minLength 1 */
  languageCode: string;
  /** @minLength 1 */
  dictionaryKey: string;
  isFallback: boolean;
}

export interface AddOrUpdateLineItemsRequest {
  lineItems: LineItem[];
}

export interface AddUsersToChatRequest {
  /** @format uuid */
  chatId: string;
  userIds: string[];
}

export interface Address {
  /** @minLength 1 */
  name: string;
  addressId: string;
  /** @minLength 1 */
  street: string;
  /** @minLength 1 */
  postalCode: string;
  /** @minLength 1 */
  city: string;
  /** @minLength 1 */
  country: string;
  floor?: string | null;
  door?: string | null;
}

export interface AddressLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface AddressViewObject {
  name: string;
  addressId: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
  floor?: string | null;
  door?: string | null;
}

export enum AdminRouteAreaAccess {
  Dashboard = "Dashboard",
  Products = "Products",
  ProductGroups = "ProductGroups",
  ProductCategories = "ProductCategories",
  Customers = "Customers",
  Users = "Users",
  Dictionary = "Dictionary",
  Statistics = "Statistics",
  Administration = "Administration",
  Auth = "Auth",
  Orders = "Orders",
}

export interface Base64EncodedFile {
  fileName: string;
  data: string;
}

export interface Base64EncodedImage {
  /** @minLength 1 */
  fileName: string;
  /** @minLength 1 */
  data: string;
}

export interface BooleanClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: boolean;
}

export interface CartLineItemViewObject {
  /** @format uuid */
  cartId: string;
  /** @format uuid */
  productId: string;
  /** @format int32 */
  amount: number;
  description?: string | null;
}

export interface CartViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  customerId: string;
  created: string;
  lastAccessed: string;
  receiverAddress: AddressViewObject;
  description?: string | null;
  requisition?: string | null;
  lineItems: CartLineItemViewObject[];
}

export interface CartViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CartViewObject;
}

export interface CategoryTreeNode {
  category: CategoryTreeNodeCategory;
  children: CategoryTreeNode[];
  products: CategoryTreeNodeProduct[];
}

export interface CategoryTreeNodeCategory {
  /** @format uuid */
  id: string;
  name: string;
  url: string;
}

export interface CategoryTreeNodeProduct {
  /** @format uuid */
  id: string;
  name: string;
  url: string;
}

export interface CategoryTreeViewModel {
  nodes: CategoryTreeNode[];
}

export interface CategoryTreeViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CategoryTreeViewModel;
}

export interface ChangePasswordRequest {
  /** @minLength 1 */
  currentPassword: string;
  /** @minLength 1 */
  newPassword: string;
  /** @minLength 1 */
  newPasswordConfirm: string;
}

export type ContactViewObject = GuidEntityViewObject & {
  name: string;
  lastName: string;
  fullName: string;
  email?: string | null;
  phoneNumber?: string | null;
  /** @format uuid */
  createdBy?: string | null;
};

export interface ContentBlockSettings {
  fullWidth: boolean;
}

export interface ContentBlockViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  styling?: CssStyleDeclaration | null;
  settings?: ContentBlockSettings | null;
}

export interface ContentPageSettings {
  hideBreadcrumb: boolean;
}

export interface ContentPageViewObject {
  url: string;
  title?: string | null;
  blocks: (ImageBlockViewObject | InfoActionBlockViewObject | TextBlockViewObject)[];
  styling?: CssStyleDeclaration | null;
  settings?: ContentPageSettings | null;
}

export interface ContentPageViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ContentPageViewObject;
}

export type CopyProductRequest = CreateProductRequest & {
  /** @format uuid */
  productId: string;
  isVariant: boolean;
};

export interface CreateChatRequest {
  members: string[];
  /** @maxLength 255 */
  title?: string | null;
}

export interface CreateCustomerRequest {
  /** @minLength 1 */
  name: string;
  cvr?: string | null;
  showProductsWithoutAccess: boolean;
  requireRequisitionOnOrder: boolean;
  productListingsView: ProductListingsView;
  type: CustomerType;
  address: Address;
  base64EncodedLogo?: Base64EncodedImage | null;
}

export interface CreateProductCategoryRequest {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  image?: Base64EncodedImage | null;
  /** @format uuid */
  parentId?: string | null;
  /** @format int32 */
  sort: number;
}

export interface CreateProductGroupRequest {
  /** @minLength 1 */
  name: string;
  description?: string | null;
}

export interface CreateProductRequest {
  /** @minLength 1 */
  name: string;
  description?: string | null;
  /** @minLength 1 */
  itemId: string;
  hidden: boolean;
  disabled: boolean;
  showForAnonymousUsers: boolean;
  images?: Base64EncodedImage[] | null;
}

export interface CreateUserRequest {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  lastName: string;
  /** @minLength 1 */
  email: string;
  /** @minLength 1 */
  phone: string;
  role: UserRole;
  profileImageUrl?: string | null;
  identifier?: string | null;
}

export interface CssStyleDeclaration {
  font?: Font | null;
  padding?: Padding | null;
  margin?: Margin | null;
}

export interface CustomerActionLogViewModel {
  logs: CustomerActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface CustomerActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerActionLogViewModel;
}

export type CustomerActionLogViewObject = CustomerLogActionLogViewObject;

export interface CustomerAddressViewModel {
  addresses: CustomerAddressViewObject[];
}

export interface CustomerAddressViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerAddressViewModel;
}

export type CustomerAddressViewObject = GuidEntityViewObject & {
  /** @format uuid */
  customerId: string;
  address: AddressViewObject;
};

export interface CustomerAddressViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerAddressViewObject;
}

export interface CustomerContact {
  /** @format uuid */
  id?: string | null;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
  isDefault: boolean;
}

export interface CustomerContactLog {
  changes?: PropertyChangeEvent[] | null;
}

export type CustomerContactViewObject = ContactViewObject & {
  /** @format uuid */
  customerId: string;
  isDefault: boolean;
};

export type CustomerLockViewObject = GuidEntityLockViewObject;

export interface CustomerLog {
  changes?: PropertyChangeEvent[] | null;
  addressLog?: AddressLog | null;
  contactLogs?: CustomerContactLog[] | null;
}

export interface CustomerLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: CustomerLog | null;
}

export interface CustomerNoteViewModel {
  notes: CustomerNoteViewObject[];
  /** @format int32 */
  total: number;
}

export interface CustomerNoteViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerNoteViewModel;
}

export interface CustomerNoteViewObject {
  /** @format int32 */
  id: number;
  note: string;
  date: string;
  /** @format uuid */
  createdBy?: string | null;
  created: string;
  /** @format uuid */
  modifiedBy?: string | null;
  modified?: string | null;
}

export interface CustomerNoteViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerNoteViewObject;
}

export enum CustomerType {
  Shop = "Shop",
  Documentation = "Documentation",
}

export interface CustomerUserAccess {
  /** @format uuid */
  customerId: string;
  allowUploadingSecurityFiles: boolean;
}

export interface CustomerUserAccessViewObject {
  /** @format uuid */
  userId: string;
  allowUploadingSecurityFiles: boolean;
}

export interface CustomerViewModel {
  customer: CustomerViewObject;
}

export interface CustomerViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomerViewModel;
}

export type CustomerViewObject = GuidEntityViewObject & {
  name: string;
  logoUrl?: string | null;
  address: AddressViewObject;
  showProductsWithoutAccess: boolean;
  requireRequisitionOnOrder: boolean;
  productListingsView: ProductListingsView;
  type: CustomerType;
  /** @format int32 */
  userCount: number;
  /** @format int32 */
  activeUserCount: number;
  /** @format int32 */
  orderCount: number;
  contacts: CustomerContactViewObject[];
  productAccesses: string[];
  productGroupAccesses: string[];
  userAccesses: CustomerUserAccessViewObject[];
  lock?: CustomerLockViewObject | null;
};

export interface CustomersByIdRequest {
  customerIds: string[];
}

export interface CustomersViewModel {
  customers: CustomerViewObject[];
}

export interface CustomersViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: CustomersViewModel;
}

export interface DeleteChatRequest {
  /** @format uuid */
  chatId: string;
}

export interface DictionaryLabelByLanguageViewModel {
  label: DictionaryLabelByLanguagesViewObject;
}

export interface DictionaryLabelByLanguageViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLabelByLanguageViewModel;
}

export interface DictionaryLabelByLanguagesViewObject {
  key: string;
  labels: DictionaryLabelViewObject[];
}

export enum DictionaryLabelContentType {
  Text = "Text",
  HTML = "HTML",
}

export interface DictionaryLabelViewObject {
  key: string;
  text: string;
  area?: string | null;
  languageCode: string;
  lastModified?: string | null;
  /** @format uuid */
  lastModifiedBy?: string | null;
  contentType: DictionaryLabelContentType;
}

export interface DictionaryLabelsByLanguageViewModel {
  labels: DictionaryLabelByLanguagesViewObject[];
}

export interface DictionaryLabelsByLanguageViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLabelsByLanguageViewModel;
}

export interface DictionaryLabelsViewModel {
  labels: DictionaryLabelViewObject[];
}

export interface DictionaryLabelsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLabelsViewModel;
}

export interface DictionaryLanguageViewModel {
  language: DictionaryLanguageViewObject;
}

export interface DictionaryLanguageViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLanguageViewModel;
}

export interface DictionaryLanguageViewObject {
  languageCode: string;
  countryCode: string;
  dictionaryKey: string;
  isFallback: boolean;
}

export interface DictionaryLanguagesViewModel {
  languages: DictionaryLanguageViewObject[];
}

export interface DictionaryLanguagesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: DictionaryLanguagesViewModel;
}

export enum EntityActionLogType {
  User = "User",
  Customer = "Customer",
  Product = "Product",
  ProductGroup = "ProductGroup",
  ProductCategory = "ProductCategory",
}

export interface Font {
  /** @format double */
  size?: number | null;
  /** @format double */
  weight?: number | null;
}

export interface GeoLocation {
  /** @format double */
  accuracy: number;
  /** @format double */
  latitude: number;
  /** @format double */
  longitude: number;
  /** @format double */
  altitude?: number | null;
  /** @format double */
  altitudeAccuracy?: number | null;
  /** @format double */
  heading?: number | null;
  /** @format double */
  speed?: number | null;
}

export interface GlobalActionLogViewModel {
  logs: (
    | ActionLogViewObject1
    | CustomerActionLogViewObject
    | ProductActionLogViewObject
    | ProductGroupActionLogViewObject
    | UserActionLogViewObject
  )[];
  /** @format int32 */
  count: number;
}

export interface GlobalActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GlobalActionLogViewModel;
}

export type GlobalLogsRequest = LogsRequest & {
  logTypes?: EntityActionLogType[] | null;
};

export interface GroupChatMemberViewObject {
  /** @format uuid */
  userId: string;
  joinedDate: string;
}

export interface GroupChatMessageViewObject {
  /** @format uuid */
  id: string;
  /** @format uuid */
  chatId: string;
  /** @format uuid */
  senderUserId: string;
  message: string;
  created: string;
  modified: string;
  readBy: string[];
}

export interface GroupChatMessageViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatMessageViewObject;
}

export interface GroupChatMessagesViewModel {
  /** @format int32 */
  total: number;
  messages: GroupChatMessageViewObject[];
}

export interface GroupChatMessagesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatMessagesViewModel;
}

export interface GroupChatViewModel {
  groupChats: GroupChatViewObject[];
}

export interface GroupChatViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatViewModel;
}

export interface GroupChatViewObject {
  /** @format uuid */
  id: string;
  title?: string | null;
  created: string;
  updated?: string | null;
  /** @format uuid */
  createdBy?: string | null;
  members: GroupChatMemberViewObject[];
  messages: GroupChatMessageViewObject[];
}

export interface GroupChatViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: GroupChatViewObject;
}

export interface GuidEntityLockViewObject {
  /** @format uuid */
  entityId: string;
  /** @format uuid */
  userId: string;
  userName: string;
  lockAcquired: string;
  lockReason: string;
  connectionId: string;
}

export interface GuidEntityViewObject {
  /** @format uuid */
  id: string;
  created: string;
  modified?: string | null;
  /** @format uuid */
  modifiedBy?: string | null;
}

export type HttpValidationProblemDetails = ProblemDetails & {
  errors: Record<string, string[]>;
  [key: string]: any;
};

export interface HubConnectionViewObject {
  /** @format uuid */
  userId: string;
  /** @format uuid */
  customerId?: string | null;
  connectionId: string;
  ipAddress?: string | null;
  connected: string;
  tokenVersion: string;
  activity: string;
  /** @format uuid */
  isTypingInChat?: string | null;
  lastActivity: string;
}

export type ImageBlockViewObject = UtilRequiredKeys<ContentBlockViewObject, "TypeDiscriminator"> & {
  imageUrls: string[];
  enableFullScreen: boolean;
  /** @format int32 */
  autoPlayInterval: number;
  /** @format int32 */
  slidesPerView: number;
  TypeDiscriminator: string;
};

export interface ImagesUploadedResponse {
  imageUrls: string[];
}

export interface ImagesUploadedResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ImagesUploadedResponse;
}

export type InfoActionBlockViewObject = UtilRequiredKeys<ContentBlockViewObject, "TypeDiscriminator"> & {
  text?: string | null;
  url: string;
  TypeDiscriminator: string;
};

export interface IpInformationViewObject {
  ip: string;
  zip: string;
  city: string;
  country: string;
  countryCode: string;
}

export interface IpInformationViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: IpInformationViewObject;
}

export interface LineItem {
  /** @format uuid */
  productId: string;
  /** @format int32 */
  amount: number;
  description?: string | null;
}

export interface LoginLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface LoginRequest {
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /** @minLength 8 */
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserViewObject;
}

export interface LoginResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: LoginResponse | RefreshSessionResponse;
}

export interface LogsRequest {
  userIds?: string[] | null;
  /** @format uuid */
  id?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  /** @format int32 */
  skip: number;
  /** @format int32 */
  take: number;
}

export interface Margin {
  /** @format double */
  top?: number | null;
  /** @format double */
  right?: number | null;
  /** @format double */
  bottom?: number | null;
  /** @format double */
  left?: number | null;
}

export interface MarkChatAsReadByUserRequest {
  /** @format uuid */
  chatId: string;
}

export interface MarkLabelAsMissingRequest {
  /** @minLength 1 */
  key: string;
  /** @minLength 1 */
  language: string;
  /** @minLength 1 */
  location: string;
}

export interface OrderLineItemViewObject {
  /** @format uuid */
  orderId: string;
  /** @format uuid */
  productId: string;
  /** @format int32 */
  amount: number;
  description?: string | null;
}

export enum OrderStatus {
  Pending = "Pending",
  Received = "Received",
  InProgress = "InProgress",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export type OrderViewObject = GuidEntityViewObject & {
  /** @format uuid */
  customerId: string;
  status: OrderStatus;
  description?: string | null;
  requisition?: string | null;
  receiverAddress: AddressViewObject;
  lineItems: OrderLineItemViewObject[];
};

export interface OrderViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: OrderViewObject;
}

export interface OrdersViewModel {
  orders: OrderViewObject[];
}

export interface Padding {
  /** @format double */
  top: number;
  /** @format double */
  right: number;
  /** @format double */
  bottom: number;
  /** @format double */
  left: number;
}

export type PagedOrdersViewModel = OrdersViewModel & {
  /** @format int32 */
  total: number;
};

export interface PagedOrdersViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: PagedOrdersViewModel;
}

export interface PostalCodeInformation {
  number: string;
  name: string;
}

export interface PostalCodeSearchResponse {
  postalCodeInformations: PostalCodeInformation[];
}

export interface PostalCodeSearchResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: PostalCodeSearchResponse;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export type ProductActionLogViewObject = ProductLogActionLogViewObject;

export interface ProductAndCategoriesViewModel {
  products: ProductViewObject[];
  categories: ProductCategoryViewObject[];
}

export interface ProductAsset {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  name: string;
  description?: string | null;
  file: Base64EncodedFile;
}

export interface ProductAssetLog {
  changes?: PropertyChangeEvent[] | null;
  /** @format uuid */
  id: string;
}

export type ProductAssetViewObject = GuidEntityViewObject & {
  name: string;
  description?: string | null;
  url: string;
  /** @format int32 */
  order: number;
};

export interface ProductCategoriesViewModel {
  categories: ProductCategoryViewObject[];
}

export interface ProductCategoriesViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ProductCategoriesViewModel;
}

export type ProductCategoryViewObject = GuidEntityViewObject & {
  name: string;
  description?: string | null;
  imageUrl?: string | null;
  /** @format uuid */
  parent?: string | null;
  /** @format int32 */
  sort: number;
  children: string[];
  products: string[];
};

export interface ProductCategoryViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ProductCategoryViewObject;
}

export interface ProductCustomerAsset {
  /** @format uuid */
  id: string;
  /** @minLength 1 */
  name: string;
  description?: string | null;
  file: Base64EncodedFile;
}

export interface ProductCustomerAssetLog {
  changes?: PropertyChangeEvent[] | null;
  /** @format uuid */
  id: string;
  /** @format uuid */
  customerId: string;
}

export type ProductCustomerAssetViewObject = GuidEntityViewObject & {
  /** @format uuid */
  customerId: string;
  name: string;
  description?: string | null;
  url: string;
  /** @format int32 */
  order: number;
};

export interface ProductCustomerAssetsViewModel {
  assets: ProductCustomerAssetViewObject[];
}

export interface ProductCustomerAssetsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ProductCustomerAssetsViewModel;
}

export type ProductGroupActionLogViewObject = ProductGroupLogActionLogViewObject;

export type ProductGroupLockViewObject = GuidEntityLockViewObject;

export interface ProductGroupLog {
  changes?: PropertyChangeEvent[] | null;
}

export interface ProductGroupLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: ProductGroupLog | null;
}

export type ProductGroupViewObject = GuidEntityViewObject & {
  name: string;
  description?: string | null;
  products: string[];
  customers: string[];
  lock?: ProductGroupLockViewObject | null;
};

export interface ProductGroupViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ProductGroupViewObject;
}

export interface ProductGroupsViewModel {
  productGroups: ProductGroupViewObject[];
}

export interface ProductGroupsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ProductGroupsViewModel;
}

export type ProductImportResultViewModel = ProductAndCategoriesViewModel & {
  errors: string[];
};

export interface ProductImportResultViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ProductImportResultViewModel;
}

export interface ProductImportSettings {
  replaceImages: boolean;
  replaceAssets: boolean;
  replaceCategories: boolean;
}

export enum ProductListingsView {
  List = "List",
  Slider = "Slider",
  Categories = "Categories",
}

export type ProductLockViewObject = GuidEntityLockViewObject;

export interface ProductLog {
  changes?: PropertyChangeEvent[] | null;
  assetLogs?: ProductAssetLog[] | null;
  customerAssetLogs?: ProductCustomerAssetLog[] | null;
}

export interface ProductLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: ProductLog | null;
}

export interface ProductSEOViewObject {
  /** @format uuid */
  productId: string;
  description: string;
  keyWords: string[];
}

export type ProductViewObject = GuidEntityViewObject & {
  name: string;
  description?: string | null;
  itemId: string;
  hidden: boolean;
  showForAnonymousUsers: boolean;
  disabled: boolean;
  disabledSince?: string | null;
  seo?: ProductSEOViewObject | null;
  images: string[];
  categories: string[];
  customerAccesses: string[];
  productGroupAccesses: string[];
  variants: string[];
  assets: ProductAssetViewObject[];
  lock?: ProductLockViewObject | null;
};

export interface ProductViewObjectClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ProductViewObject;
}

export interface ProductsByIdRequest {
  productIds: string[];
}

export interface ProductsViewModel {
  products: ProductViewObject[];
}

export interface ProductsViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: ProductsViewModel;
}

export interface PropertyChangeEvent {
  propertyName: string;
  previous?: any;
  current?: any;
}

export interface PropertyInformation {
  addressId: string;
  /** @format double */
  squareMeterSize: number;
  /** @format double */
  numberOfRooms: number;
  /** @format double */
  numberOfBathrooms: number;
  /** @format int32 */
  constructionYear: number;
  /** @format int32 */
  reconstructionYear: number;
  /** @format int32 */
  numberOfFloors: number;
}

export interface PropertyInformationResponse {
  propertyInformation?: PropertyInformation | null;
}

export interface PropertyInformationResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: PropertyInformationResponse;
}

export type RefreshSessionResponse = LoginResponse;

export interface RefreshSessionResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: RefreshSessionResponse;
}

export interface RegisterRequest {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  lastName: string;
  /**
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * @format tel
   * @minLength 1
   */
  phoneNumber: string;
  /** @minLength 8 */
  password: string;
  /** @minLength 8 */
  passwordConfirm: string;
}

export interface RemoveUserFromChatRequest {
  /** @format uuid */
  chatId: string;
  userIds: string[];
}

export interface RenameChatRequest {
  /** @format uuid */
  chatId: string;
  title?: string | null;
}

export interface RequestPasswordResetRequest {
  email?: string | null;
  phoneNumber?: string | null;
}

export interface ResetPasswordRequest {
  /** @format uuid */
  resetPasswordToken: string;
  /** @minLength 1 */
  password: string;
}

export interface SearchAddressResponse {
  addressId: string;
  address: string;
  street: string;
  city: string;
  postalCode: string;
  floor?: string | null;
  door?: string | null;
}

export interface SearchAddressesResponse {
  addresses: SearchAddressResponse[];
}

export interface SearchAddressesResponseClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: SearchAddressesResponse;
}

export interface SearchOrderHistoryRequest {
  statuses: OrderStatus[];
  term?: string | null;
  /** @format int32 */
  skip: number;
  /** @format int32 */
  take: number;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
}

export interface SearchOrdersRequest {
  userIds: string[];
  customerIds: string[];
  statuses: OrderStatus[];
  term?: string | null;
  /** @format int32 */
  skip: number;
  /** @format int32 */
  take: number;
  /** @format date-time */
  start?: string | null;
  /** @format date-time */
  end?: string | null;
}

export interface SendChatMessageRequest {
  /** @format uuid */
  chatId: string;
  /** @minLength 1 */
  message: string;
}

export enum ShopRouteAreaAccess {
  Home = "Home",
  Products = "Products",
  Categories = "Categories",
  Checkout = "Checkout",
  OrderHistory = "OrderHistory",
  Auth = "Auth",
}

export interface TLog {
  changes?: PropertyChangeEvent[] | null;
}

export type TextBlockViewObject = UtilRequiredKeys<ContentBlockViewObject, "TypeDiscriminator"> & {
  text?: string | null;
  TypeDiscriminator: string;
};

export interface UpdateCustomerAccessRequest {
  /** @format uuid */
  userId: string;
  customerAccesses: CustomerUserAccess[];
}

export interface UpdateCustomerAccessesRequest {
  customerAccesses: UpdateCustomerAccessRequest[];
}

export interface UpdateCustomerProductAccessRequest {
  /** @format uuid */
  customerId: string;
  productIds: string[];
}

export interface UpdateCustomerProductAccessesRequest {
  productAccesses: UpdateCustomerProductAccessRequest[];
}

export interface UpdateCustomerProductGroupAccessRequest {
  /** @format uuid */
  customerId: string;
  productGroupIds: string[];
}

export interface UpdateCustomerProductGroupAccessesRequest {
  productGroupAccesses: UpdateCustomerProductGroupAccessRequest[];
}

export type UpdateCustomerRequest = CreateCustomerRequest & {
  /** @format uuid */
  customerId: string;
};

export interface UpdateCustomerUserAccessRequest {
  /** @format uuid */
  customerId: string;
  userAccesses: UserCustomerAccess[];
}

export interface UpdateDictionaryLabelRequest {
  /** @minLength 1 */
  key: string;
  area?: string | null;
  textsByLanguages: UpdateDictionaryLabelRequestTextByLanguage[];
}

export interface UpdateDictionaryLabelRequestTextByLanguage {
  /** @minLength 1 */
  languageCode: string;
  text: string;
  contentType: DictionaryLabelContentType;
}

export interface UpdateProductAssetsRequest {
  /** @format uuid */
  productId: string;
  assets: ProductAsset[];
  copyToVariants: boolean;
}

export interface UpdateProductCategoriesForProductRequest {
  /** @format uuid */
  productId: string;
  productCategoryIds: string[];
  copyToVariants: boolean;
}

export interface UpdateProductCategoryParentRequest {
  /** @format uuid */
  categoryId: string;
  /** @format uuid */
  parentId?: string | null;
}

export type UpdateProductCategoryRequest = CreateProductCategoryRequest & {
  /** @format uuid */
  categoryId: string;
};

export interface UpdateProductCategorySortOrderRequest {
  sortOrders: Record<string, number>;
}

export interface UpdateProductCustomerAccessesRequest {
  /** @format uuid */
  productId: string;
  customerIds: string[];
}

export interface UpdateProductCustomerAssetsRequest {
  /** @format uuid */
  productId: string;
  /** @format uuid */
  customerId: string;
  assets: ProductCustomerAsset[];
}

export interface UpdateProductGroupCustomersRequest {
  /** @format uuid */
  productGroupId: string;
  customerIds: string[];
}

export interface UpdateProductGroupProductAccessRequest {
  /** @format uuid */
  productId: string;
  productGroupIds: string[];
}

export interface UpdateProductGroupProductAccessesRequest {
  productGroupAccesses: UpdateProductGroupProductAccessRequest[];
}

export interface UpdateProductGroupProductsRequest {
  /** @format uuid */
  productGroupId: string;
  productIds: string[];
}

export type UpdateProductGroupRequest = CreateProductGroupRequest & {
  /** @format uuid */
  groupId: string;
};

export interface UpdateProductImagesRequest {
  /** @format uuid */
  productId: string;
  images: Base64EncodedImage[];
  copyToVariants: boolean;
}

export type UpdateProductRequest = CreateProductRequest & {
  /** @format uuid */
  productId: string;
};

export interface UpdateProductSEORequest {
  /** @format uuid */
  productId: string;
  /** @maxLength 150 */
  description?: string | null;
  /** @maxItems 10 */
  keyWords?: string[] | null;
}

export interface UpdateProductVariantsRequest {
  /** @format uuid */
  productId: string;
  variantIds: string[];
}

export interface UpdateProductsForProductCategoryRequest {
  /** @format uuid */
  categoryId: string;
  productIds: string[];
}

export type UpdateUserRequest = CreateUserRequest & {
  /** @format uuid */
  userId: string;
};

export interface UserActionLogViewModel {
  logs: UserActionLogViewObject[];
  /** @format int32 */
  count: number;
}

export interface UserActionLogViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: UserActionLogViewModel;
}

export type UserActionLogViewObject = UserLogActionLogViewObject;

export interface UserCustomerAccess {
  /** @format uuid */
  userId: string;
  allowUploadingSecurityFiles: boolean;
}

export interface UserCustomerAccessViewObject {
  /** @format uuid */
  customerId: string;
  allowUploadingSecurityFiles: boolean;
}

export interface UserLog {
  changes?: PropertyChangeEvent[] | null;
  loginLog?: LoginLog | null;
}

export interface UserLogActionLogViewObject {
  TypeDiscriminator: string;
  /** @format uuid */
  id: string;
  /** @format uuid */
  entityId: string;
  created: string;
  /** @format uuid */
  createdBy: string;
  description: string;
  logType: EntityActionLogType;
  log?: UserLog | null;
}

export interface UserLoginHistoryViewModel {
  loginHistories: UserLoginHistoryViewObject[];
}

export interface UserLoginHistoryViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: UserLoginHistoryViewModel;
}

export interface UserLoginHistoryViewObject {
  /** @format uuid */
  userId: string;
  ip: string;
  date: string;
  logoutDate?: string | null;
  /** @format int32 */
  sessionDuration?: number | null;
}

export enum UserRole {
  None = "None",
  Administrator = "Administrator",
}

export interface UserViewModel {
  user: UserViewObject;
}

export interface UserViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: UserViewModel;
}

export type UserViewObject = GuidEntityViewObject & {
  name: string;
  email: string;
  phone?: string | null;
  lastName: string;
  fullName: string;
  blocked: boolean;
  deletedSince?: string | null;
  isStaff: boolean;
  lastLogin?: string | null;
  lastIp?: string | null;
  profileImageUrl?: string | null;
  identifier?: string | null;
  role: UserRole;
  allowableIps: string[];
  customerAccesses: UserCustomerAccessViewObject[];
  activeConnections?: HubConnectionViewObject[] | null;
};

export interface UsersByIdRequest {
  userIds: string[];
}

export interface UsersViewModel {
  users: UserViewObject[];
}

export interface UsersViewModelClientResponse {
  validationMessages?: ValidationMessage[] | null;
  correlationId?: string | null;
  /** @format double */
  serverTimeMs?: number | null;
  model: UsersViewModel;
}

export interface ValidationMessage {
  message: string;
  args?: any[] | null;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Socco API
 * @version v1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthLoginCreate
     * @request POST:/api/Auth/Login
     * @secure
     */
    authLoginCreate: (data: LoginRequest, params: RequestParams = {}) =>
      this.request<LoginResponseClientResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/Auth/Login`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthRegisterCreate
     * @request POST:/api/Auth/Register
     * @secure
     */
    authRegisterCreate: (data: RegisterRequest, params: RequestParams = {}) =>
      this.request<LoginResponseClientResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/Auth/Register`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthRefreshSessionList
     * @request GET:/api/Auth/RefreshSession
     * @secure
     */
    authRefreshSessionList: (params: RequestParams = {}) =>
      this.request<RefreshSessionResponseClientResponse, ProblemDetails | HttpValidationProblemDetails>({
        path: `/api/Auth/RefreshSession`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthChangePasswordCreate
     * @request POST:/api/Auth/ChangePassword
     * @secure
     */
    authChangePasswordCreate: (data: ChangePasswordRequest, params: RequestParams = {}) =>
      this.request<RefreshSessionResponseClientResponse, any>({
        path: `/api/Auth/ChangePassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthRequestPasswordResetCreate
     * @request POST:/api/Auth/RequestPasswordReset
     * @secure
     */
    authRequestPasswordResetCreate: (data: RequestPasswordResetRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Auth/RequestPasswordReset`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthResetPasswordCreate
     * @request POST:/api/Auth/ResetPassword
     * @secure
     */
    authResetPasswordCreate: (data: ResetPasswordRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Auth/ResetPassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationSendChatMessageCreate
     * @request POST:/api/Communication/SendChatMessage
     * @secure
     */
    communicationSendChatMessageCreate: (data: SendChatMessageRequest, params: RequestParams = {}) =>
      this.request<GroupChatMessageViewObjectClientResponse, any>({
        path: `/api/Communication/SendChatMessage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationMarkChatAsReadByUserCreate
     * @request POST:/api/Communication/MarkChatAsReadByUser
     * @secure
     */
    communicationMarkChatAsReadByUserCreate: (data: MarkChatAsReadByUserRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Communication/MarkChatAsReadByUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationCreateChatCreate
     * @request POST:/api/Communication/CreateChat
     * @secure
     */
    communicationCreateChatCreate: (data: CreateChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/CreateChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationDeleteChatCreate
     * @request POST:/api/Communication/DeleteChat
     * @secure
     */
    communicationDeleteChatCreate: (data: DeleteChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/DeleteChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationRenameChatCreate
     * @request POST:/api/Communication/RenameChat
     * @secure
     */
    communicationRenameChatCreate: (data: RenameChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/RenameChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationAddUsersToChatCreate
     * @request POST:/api/Communication/AddUsersToChat
     * @secure
     */
    communicationAddUsersToChatCreate: (data: AddUsersToChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/AddUsersToChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationRemoveUsersFromChatCreate
     * @request POST:/api/Communication/RemoveUsersFromChat
     * @secure
     */
    communicationRemoveUsersFromChatCreate: (data: RemoveUserFromChatRequest, params: RequestParams = {}) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/RemoveUsersFromChat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationGetChatList
     * @request GET:/api/Communication/GetChat
     * @secure
     */
    communicationGetChatList: (
      query: {
        /** @format uuid */
        chatId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupChatViewObjectClientResponse, any>({
        path: `/api/Communication/GetChat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationGetChatsList
     * @request GET:/api/Communication/GetChats
     * @secure
     */
    communicationGetChatsList: (params: RequestParams = {}) =>
      this.request<GroupChatViewModelClientResponse, any>({
        path: `/api/Communication/GetChats`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Communication
     * @name CommunicationGetMessagesForChatList
     * @request GET:/api/Communication/GetMessagesForChat
     * @secure
     */
    communicationGetMessagesForChatList: (
      query: {
        /** @format uuid */
        chatId: string;
        /** @format int32 */
        skip: number;
        /** @format int32 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupChatMessagesViewModelClientResponse, any>({
        path: `/api/Communication/GetMessagesForChat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ContentPages
     * @name ContentPagesGetPageForUrlList
     * @request GET:/api/ContentPages/GetPageForUrl
     * @secure
     */
    contentPagesGetPageForUrlList: (
      query: {
        url: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContentPageViewObjectClientResponse, any>({
        path: `/api/ContentPages/GetPageForUrl`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCustomerList
     * @request GET:/api/Customer/Customer
     * @secure
     */
    customerCustomerList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/Customer`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCustomersList
     * @request GET:/api/Customer/Customers
     * @secure
     */
    customerCustomersList: (params: RequestParams = {}) =>
      this.request<CustomersViewModelClientResponse, any>({
        path: `/api/Customer/Customers`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCustomersByIdCreate
     * @request POST:/api/Customer/CustomersById
     * @secure
     */
    customerCustomersByIdCreate: (data: CustomersByIdRequest, params: RequestParams = {}) =>
      this.request<CustomersViewModelClientResponse, any>({
        path: `/api/Customer/CustomersById`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetAvailableCustomersForUserList
     * @request GET:/api/Customer/GetAvailableCustomersForUser
     * @secure
     */
    customerGetAvailableCustomersForUserList: (params: RequestParams = {}) =>
      this.request<CustomersViewModelClientResponse, any>({
        path: `/api/Customer/GetAvailableCustomersForUser`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerCreateCustomerCreate
     * @request POST:/api/Customer/CreateCustomer
     * @secure
     */
    customerCreateCustomerCreate: (data: CreateCustomerRequest | UpdateCustomerRequest, params: RequestParams = {}) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/CreateCustomer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateCustomerCreate
     * @request POST:/api/Customer/UpdateCustomer
     * @secure
     */
    customerUpdateCustomerCreate: (data: UpdateCustomerRequest, params: RequestParams = {}) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/UpdateCustomer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerDeleteCustomerCreate
     * @request POST:/api/Customer/DeleteCustomer
     * @secure
     */
    customerDeleteCustomerCreate: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/DeleteCustomer`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetNotesList
     * @request GET:/api/Customer/GetNotes
     * @secure
     */
    customerGetNotesList: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format int32 */
        skip: number;
        /** @format int32 */
        take: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerNoteViewModelClientResponse, any>({
        path: `/api/Customer/GetNotes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateNoteCreate
     * @request POST:/api/Customer/AddOrUpdateNote
     * @secure
     */
    customerAddOrUpdateNoteCreate: (data: AddOrUpdateCustomerNoteRequest, params: RequestParams = {}) =>
      this.request<CustomerNoteViewObjectClientResponse, any>({
        path: `/api/Customer/AddOrUpdateNote`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerDeleteNoteDelete
     * @request DELETE:/api/Customer/DeleteNote
     * @secure
     */
    customerDeleteNoteDelete: (
      query: {
        /** @format uuid */
        customerId: string;
        /** @format int32 */
        noteId: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerNoteViewObjectClientResponse, any>({
        path: `/api/Customer/DeleteNote`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateCustomerContactsCreate
     * @request POST:/api/Customer/AddOrUpdateCustomerContacts
     * @secure
     */
    customerAddOrUpdateCustomerContactsCreate: (data: AddOrUpdateCustomerContactsRequest, params: RequestParams = {}) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/AddOrUpdateCustomerContacts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateProductAccessForCustomerCreate
     * @request POST:/api/Customer/UpdateProductAccessForCustomer
     * @secure
     */
    customerUpdateProductAccessForCustomerCreate: (
      data: UpdateCustomerProductAccessRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/UpdateProductAccessForCustomer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateProductAccessForCustomersCreate
     * @request POST:/api/Customer/UpdateProductAccessForCustomers
     * @secure
     */
    customerUpdateProductAccessForCustomersCreate: (
      data: UpdateCustomerProductAccessesRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomersViewModelClientResponse, any>({
        path: `/api/Customer/UpdateProductAccessForCustomers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateProductGroupAccessForCustomerCreate
     * @request POST:/api/Customer/UpdateProductGroupAccessForCustomer
     * @secure
     */
    customerUpdateProductGroupAccessForCustomerCreate: (
      data: UpdateCustomerProductGroupAccessRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/UpdateProductGroupAccessForCustomer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateUserAccessForCustomerCreate
     * @request POST:/api/Customer/UpdateUserAccessForCustomer
     * @secure
     */
    customerUpdateUserAccessForCustomerCreate: (data: UpdateCustomerUserAccessRequest, params: RequestParams = {}) =>
      this.request<CustomerViewModelClientResponse, any>({
        path: `/api/Customer/UpdateUserAccessForCustomer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerUpdateProductGroupAccessForCustomersCreate
     * @request POST:/api/Customer/UpdateProductGroupAccessForCustomers
     * @secure
     */
    customerUpdateProductGroupAccessForCustomersCreate: (
      data: UpdateCustomerProductGroupAccessesRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomersViewModelClientResponse, any>({
        path: `/api/Customer/UpdateProductGroupAccessForCustomers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetAddressesList
     * @request GET:/api/Customer/GetAddresses
     * @secure
     */
    customerGetAddressesList: (params: RequestParams = {}) =>
      this.request<CustomerAddressViewModelClientResponse, any>({
        path: `/api/Customer/GetAddresses`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerGetAddressesForCustomerList
     * @request GET:/api/Customer/GetAddressesForCustomer
     * @secure
     */
    customerGetAddressesForCustomerList: (
      query: {
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerAddressViewModelClientResponse, any>({
        path: `/api/Customer/GetAddressesForCustomer`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateAddressCreate
     * @request POST:/api/Customer/AddOrUpdateAddress
     * @secure
     */
    customerAddOrUpdateAddressCreate: (data: AddOrUpdateCustomerAddressRequest, params: RequestParams = {}) =>
      this.request<CustomerAddressViewObjectClientResponse, any>({
        path: `/api/Customer/AddOrUpdateAddress`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomerAddOrUpdateAddressForCustomerCreate
     * @request POST:/api/Customer/AddOrUpdateAddressForCustomer
     * @secure
     */
    customerAddOrUpdateAddressForCustomerCreate: (
      data: AddOrUpdateCustomerAddressRequest,
      params: RequestParams = {},
    ) =>
      this.request<CustomerAddressViewObjectClientResponse, any>({
        path: `/api/Customer/AddOrUpdateAddressForCustomer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryCacheBustList
     * @request GET:/api/Dictionary/CacheBust
     * @secure
     */
    dictionaryCacheBustList: (params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Dictionary/CacheBust`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryCacheBustCreate
     * @request POST:/api/Dictionary/CacheBust
     * @secure
     */
    dictionaryCacheBustCreate: (params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Dictionary/CacheBust`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryGetSupportedAdminLanguagesList
     * @request GET:/api/Dictionary/GetSupportedAdminLanguages
     * @secure
     */
    dictionaryGetSupportedAdminLanguagesList: (params: RequestParams = {}) =>
      this.request<DictionaryLanguagesViewModelClientResponse, any>({
        path: `/api/Dictionary/GetSupportedAdminLanguages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryGetAdminDictionaryLabelsForAreaList
     * @request GET:/api/Dictionary/GetAdminDictionaryLabelsForArea
     * @secure
     */
    dictionaryGetAdminDictionaryLabelsForAreaList: (
      query?: {
        area?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLabelsViewModelClientResponse, any>({
        path: `/api/Dictionary/GetAdminDictionaryLabelsForArea`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryGetAdminDictionaryLabelsList
     * @request GET:/api/Dictionary/GetAdminDictionaryLabels
     * @secure
     */
    dictionaryGetAdminDictionaryLabelsList: (params: RequestParams = {}) =>
      this.request<DictionaryLabelsByLanguageViewModelClientResponse, any>({
        path: `/api/Dictionary/GetAdminDictionaryLabels`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryGetSupportedShopLanguagesList
     * @request GET:/api/Dictionary/GetSupportedShopLanguages
     * @secure
     */
    dictionaryGetSupportedShopLanguagesList: (params: RequestParams = {}) =>
      this.request<DictionaryLanguagesViewModelClientResponse, any>({
        path: `/api/Dictionary/GetSupportedShopLanguages`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryGetShopDictionaryLabelsForAreaList
     * @request GET:/api/Dictionary/GetShopDictionaryLabelsForArea
     * @secure
     */
    dictionaryGetShopDictionaryLabelsForAreaList: (
      query?: {
        area?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLabelsViewModelClientResponse, any>({
        path: `/api/Dictionary/GetShopDictionaryLabelsForArea`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryGetShopDictionaryLabelsList
     * @request GET:/api/Dictionary/GetShopDictionaryLabels
     * @secure
     */
    dictionaryGetShopDictionaryLabelsList: (params: RequestParams = {}) =>
      this.request<DictionaryLabelsByLanguageViewModelClientResponse, any>({
        path: `/api/Dictionary/GetShopDictionaryLabels`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryMarkAdminLabelAsMissingCreate
     * @request POST:/api/Dictionary/MarkAdminLabelAsMissing
     * @secure
     */
    dictionaryMarkAdminLabelAsMissingCreate: (data: MarkLabelAsMissingRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Dictionary/MarkAdminLabelAsMissing`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryMarkShopLabelAsMissingCreate
     * @request POST:/api/Dictionary/MarkShopLabelAsMissing
     * @secure
     */
    dictionaryMarkShopLabelAsMissingCreate: (data: MarkLabelAsMissingRequest, params: RequestParams = {}) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Dictionary/MarkShopLabelAsMissing`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryAddOrUpdateAdminDictionaryLabelCreate
     * @request POST:/api/Dictionary/AddOrUpdateAdminDictionaryLabel
     * @secure
     */
    dictionaryAddOrUpdateAdminDictionaryLabelCreate: (data: UpdateDictionaryLabelRequest, params: RequestParams = {}) =>
      this.request<DictionaryLabelByLanguageViewModelClientResponse, any>({
        path: `/api/Dictionary/AddOrUpdateAdminDictionaryLabel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryAddOrUpdateAdminDictionaryLanguageCreate
     * @request POST:/api/Dictionary/AddOrUpdateAdminDictionaryLanguage
     * @secure
     */
    dictionaryAddOrUpdateAdminDictionaryLanguageCreate: (
      data: AddOrUpdateDictionaryLanguageRequest,
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLanguageViewModelClientResponse, any>({
        path: `/api/Dictionary/AddOrUpdateAdminDictionaryLanguage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryAddOrUpdateShopDictionaryLabelCreate
     * @request POST:/api/Dictionary/AddOrUpdateShopDictionaryLabel
     * @secure
     */
    dictionaryAddOrUpdateShopDictionaryLabelCreate: (data: UpdateDictionaryLabelRequest, params: RequestParams = {}) =>
      this.request<DictionaryLabelByLanguageViewModelClientResponse, any>({
        path: `/api/Dictionary/AddOrUpdateShopDictionaryLabel`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Dictionary
     * @name DictionaryAddOrUpdateShopDictionaryLanguageCreate
     * @request POST:/api/Dictionary/AddOrUpdateShopDictionaryLanguage
     * @secure
     */
    dictionaryAddOrUpdateShopDictionaryLanguageCreate: (
      data: AddOrUpdateDictionaryLanguageRequest,
      params: RequestParams = {},
    ) =>
      this.request<DictionaryLanguageViewModelClientResponse, any>({
        path: `/api/Dictionary/AddOrUpdateShopDictionaryLanguage`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Image
     * @name ImageUploadImagesUpdate
     * @request PUT:/api/Image/UploadImages
     * @secure
     */
    imageUploadImagesUpdate: (
      query: {
        /** @format uuid */
        uniqueId: string;
      },
      data: {
        images?: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ImagesUploadedResponseClientResponse, any>({
        path: `/api/Image/UploadImages`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Image
     * @name ImageReplaceImagesUpdate
     * @request PUT:/api/Image/ReplaceImages
     * @secure
     */
    imageReplaceImagesUpdate: (
      query: {
        /** @format uuid */
        uniqueId: string;
      },
      data: {
        images?: File[];
      },
      params: RequestParams = {},
    ) =>
      this.request<ImagesUploadedResponseClientResponse, any>({
        path: `/api/Image/ReplaceImages`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Image
     * @name ImageDeleteImagesDelete
     * @request DELETE:/api/Image/DeleteImages
     * @secure
     */
    imageDeleteImagesDelete: (
      query: {
        /** @format uuid */
        uniqueId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BooleanClientResponse, any>({
        path: `/api/Image/DeleteImages`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags IpInfo
     * @name IpInfoGetInfoList
     * @request GET:/api/IpInfo/GetInfo
     * @secure
     */
    ipInfoGetInfoList: (
      query: {
        ip: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IpInformationViewObjectClientResponse, any>({
        path: `/api/IpInfo/GetInfo`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetCustomerLogsCreate
     * @request POST:/api/Logs/GetCustomerLogs
     * @secure
     */
    logsGetCustomerLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<CustomerActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetCustomerLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetUserLogsCreate
     * @request POST:/api/Logs/GetUserLogs
     * @secure
     */
    logsGetUserLogsCreate: (data: LogsRequest | GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<UserActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetUserLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Logs
     * @name LogsGetGlobalLogsCreate
     * @request POST:/api/Logs/GetGlobalLogs
     * @secure
     */
    logsGetGlobalLogsCreate: (data: GlobalLogsRequest, params: RequestParams = {}) =>
      this.request<GlobalActionLogViewModelClientResponse, any>({
        path: `/api/Logs/GetGlobalLogs`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetProductList
     * @request GET:/api/Products/GetProduct
     * @secure
     */
    productsGetProductList: (
      query: {
        /** @format uuid */
        productId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/GetProduct`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetProductGroupList
     * @request GET:/api/Products/GetProductGroup
     * @secure
     */
    productsGetProductGroupList: (
      query: {
        /** @format uuid */
        groupId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductGroupViewObjectClientResponse, any>({
        path: `/api/Products/GetProductGroup`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetProductsList
     * @request GET:/api/Products/GetProducts
     * @secure
     */
    productsGetProductsList: (params: RequestParams = {}) =>
      this.request<ProductsViewModelClientResponse, any>({
        path: `/api/Products/GetProducts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetAvailableProductsList
     * @request GET:/api/Products/GetAvailableProducts
     * @secure
     */
    productsGetAvailableProductsList: (params: RequestParams = {}) =>
      this.request<ProductsViewModelClientResponse, any>({
        path: `/api/Products/GetAvailableProducts`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetAvailableProductsForCustomerList
     * @request GET:/api/Products/GetAvailableProductsForCustomer
     * @secure
     */
    productsGetAvailableProductsForCustomerList: (params: RequestParams = {}) =>
      this.request<ProductsViewModelClientResponse, any>({
        path: `/api/Products/GetAvailableProductsForCustomer`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetCategoryTreeList
     * @request GET:/api/Products/GetCategoryTree
     * @secure
     */
    productsGetCategoryTreeList: (
      query?: {
        includeProducts?: boolean;
        sort?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<CategoryTreeViewModelClientResponse, any>({
        path: `/api/Products/GetCategoryTree`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsProductsByIdCreate
     * @request POST:/api/Products/ProductsById
     * @secure
     */
    productsProductsByIdCreate: (data: ProductsByIdRequest, params: RequestParams = {}) =>
      this.request<ProductsViewModelClientResponse, any>({
        path: `/api/Products/ProductsById`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetProductGroupsList
     * @request GET:/api/Products/GetProductGroups
     * @secure
     */
    productsGetProductGroupsList: (params: RequestParams = {}) =>
      this.request<ProductGroupsViewModelClientResponse, any>({
        path: `/api/Products/GetProductGroups`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsCreateProductCreate
     * @request POST:/api/Products/CreateProduct
     * @secure
     */
    productsCreateProductCreate: (
      data: CreateProductRequest | CopyProductRequest | UpdateProductRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/CreateProduct`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsCopyProductCreate
     * @request POST:/api/Products/CopyProduct
     * @secure
     */
    productsCopyProductCreate: (data: CopyProductRequest, params: RequestParams = {}) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/CopyProduct`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductCreate
     * @request POST:/api/Products/UpdateProduct
     * @secure
     */
    productsUpdateProductCreate: (data: UpdateProductRequest, params: RequestParams = {}) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/UpdateProduct`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsDeleteProductDelete
     * @request DELETE:/api/Products/DeleteProduct
     * @secure
     */
    productsDeleteProductDelete: (
      query: {
        /** @format uuid */
        productId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/DeleteProduct`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsCreateProductGroupCreate
     * @request POST:/api/Products/CreateProductGroup
     * @secure
     */
    productsCreateProductGroupCreate: (
      data: CreateProductGroupRequest | UpdateProductGroupRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductGroupViewObjectClientResponse, any>({
        path: `/api/Products/CreateProductGroup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductGroupCreate
     * @request POST:/api/Products/UpdateProductGroup
     * @secure
     */
    productsUpdateProductGroupCreate: (data: UpdateProductGroupRequest, params: RequestParams = {}) =>
      this.request<ProductGroupViewObjectClientResponse, any>({
        path: `/api/Products/UpdateProductGroup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsDeleteProductGroupDelete
     * @request DELETE:/api/Products/DeleteProductGroup
     * @secure
     */
    productsDeleteProductGroupDelete: (
      query: {
        /** @format uuid */
        groupId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductGroupViewObjectClientResponse, any>({
        path: `/api/Products/DeleteProductGroup`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsAddOrUpdateProductImagesCreate
     * @request POST:/api/Products/AddOrUpdateProductImages
     * @secure
     */
    productsAddOrUpdateProductImagesCreate: (data: UpdateProductImagesRequest, params: RequestParams = {}) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/AddOrUpdateProductImages`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsAddOrUpdateProductAssetsCreate
     * @request POST:/api/Products/AddOrUpdateProductAssets
     * @secure
     */
    productsAddOrUpdateProductAssetsCreate: (data: UpdateProductAssetsRequest, params: RequestParams = {}) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/AddOrUpdateProductAssets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsAddOrUpdateProductCustomerAssetsCreate
     * @request POST:/api/Products/AddOrUpdateProductCustomerAssets
     * @secure
     */
    productsAddOrUpdateProductCustomerAssetsCreate: (
      data: UpdateProductCustomerAssetsRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductCustomerAssetsViewModelClientResponse, any>({
        path: `/api/Products/AddOrUpdateProductCustomerAssets`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsAddOrUpdateProductCustomerAssetsForCustomerCreate
     * @request POST:/api/Products/AddOrUpdateProductCustomerAssetsForCustomer
     * @secure
     */
    productsAddOrUpdateProductCustomerAssetsForCustomerCreate: (
      data: UpdateProductCustomerAssetsRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductCustomerAssetsViewModelClientResponse, any>({
        path: `/api/Products/AddOrUpdateProductCustomerAssetsForCustomer`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetProductCustomerAssetsList
     * @request GET:/api/Products/GetProductCustomerAssets
     * @secure
     */
    productsGetProductCustomerAssetsList: (
      query: {
        /** @format uuid */
        productId: string;
        /** @format uuid */
        customerId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductCustomerAssetsViewModelClientResponse, any>({
        path: `/api/Products/GetProductCustomerAssets`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductVariantsForProductCreate
     * @request POST:/api/Products/UpdateProductVariantsForProduct
     * @secure
     */
    productsUpdateProductVariantsForProductCreate: (data: UpdateProductVariantsRequest, params: RequestParams = {}) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/UpdateProductVariantsForProduct`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductSeoCreate
     * @request POST:/api/Products/UpdateProductSEO
     * @secure
     */
    productsUpdateProductSeoCreate: (data: UpdateProductSEORequest, params: RequestParams = {}) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/UpdateProductSEO`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductGroupAccessForProductCreate
     * @request POST:/api/Products/UpdateProductGroupAccessForProduct
     * @secure
     */
    productsUpdateProductGroupAccessForProductCreate: (
      data: UpdateProductGroupProductAccessRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/UpdateProductGroupAccessForProduct`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductGroupAccessForProductsCreate
     * @request POST:/api/Products/UpdateProductGroupAccessForProducts
     * @secure
     */
    productsUpdateProductGroupAccessForProductsCreate: (
      data: UpdateProductGroupProductAccessesRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductsViewModelClientResponse, any>({
        path: `/api/Products/UpdateProductGroupAccessForProducts`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateCustomerProductAccessForProductCreate
     * @request POST:/api/Products/UpdateCustomerProductAccessForProduct
     * @secure
     */
    productsUpdateCustomerProductAccessForProductCreate: (
      data: UpdateProductCustomerAccessesRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/UpdateCustomerProductAccessForProduct`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductsForProductGroupCreate
     * @request POST:/api/Products/UpdateProductsForProductGroup
     * @secure
     */
    productsUpdateProductsForProductGroupCreate: (
      data: UpdateProductGroupProductsRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductGroupViewObjectClientResponse, any>({
        path: `/api/Products/UpdateProductsForProductGroup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductCategoriesForProductCreate
     * @request POST:/api/Products/UpdateProductCategoriesForProduct
     * @secure
     */
    productsUpdateProductCategoriesForProductCreate: (
      data: UpdateProductCategoriesForProductRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductViewObjectClientResponse, any>({
        path: `/api/Products/UpdateProductCategoriesForProduct`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateCustomersForProductGroupCreate
     * @request POST:/api/Products/UpdateCustomersForProductGroup
     * @secure
     */
    productsUpdateCustomersForProductGroupCreate: (
      data: UpdateProductGroupCustomersRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductGroupViewObjectClientResponse, any>({
        path: `/api/Products/UpdateCustomersForProductGroup`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsGetProductCategoriesList
     * @request GET:/api/Products/GetProductCategories
     * @secure
     */
    productsGetProductCategoriesList: (params: RequestParams = {}) =>
      this.request<ProductCategoriesViewModelClientResponse, any>({
        path: `/api/Products/GetProductCategories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsDeleteProductCategoryDelete
     * @request DELETE:/api/Products/DeleteProductCategory
     * @secure
     */
    productsDeleteProductCategoryDelete: (
      query: {
        /** @format uuid */
        categoryId: string;
        recursive: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<ProductCategoriesViewModelClientResponse, any>({
        path: `/api/Products/DeleteProductCategory`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsCreateProductCategoryCreate
     * @request POST:/api/Products/CreateProductCategory
     * @secure
     */
    productsCreateProductCategoryCreate: (
      data: CreateProductCategoryRequest | UpdateProductCategoryRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductCategoryViewObjectClientResponse, any>({
        path: `/api/Products/CreateProductCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductCategoryCreate
     * @request POST:/api/Products/UpdateProductCategory
     * @secure
     */
    productsUpdateProductCategoryCreate: (data: UpdateProductCategoryRequest, params: RequestParams = {}) =>
      this.request<ProductCategoryViewObjectClientResponse, any>({
        path: `/api/Products/UpdateProductCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductCategoryParentCreate
     * @request POST:/api/Products/UpdateProductCategoryParent
     * @secure
     */
    productsUpdateProductCategoryParentCreate: (data: UpdateProductCategoryParentRequest, params: RequestParams = {}) =>
      this.request<ProductCategoryViewObjectClientResponse, any>({
        path: `/api/Products/UpdateProductCategoryParent`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductCategorySortCreate
     * @request POST:/api/Products/UpdateProductCategorySort
     * @secure
     */
    productsUpdateProductCategorySortCreate: (
      data: UpdateProductCategorySortOrderRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductCategoriesViewModelClientResponse, any>({
        path: `/api/Products/UpdateProductCategorySort`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsUpdateProductsForProductCategoryCreate
     * @request POST:/api/Products/UpdateProductsForProductCategory
     * @secure
     */
    productsUpdateProductsForProductCategoryCreate: (
      data: UpdateProductsForProductCategoryRequest,
      params: RequestParams = {},
    ) =>
      this.request<ProductCategoryViewObjectClientResponse, any>({
        path: `/api/Products/UpdateProductsForProductCategory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Products
     * @name ProductsImportProductsFromWordPressFeedCreate
     * @request POST:/api/Products/ImportProductsFromWordPressFeed
     * @secure
     */
    productsImportProductsFromWordPressFeedCreate: (data: ProductImportSettings, params: RequestParams = {}) =>
      this.request<ProductImportResultViewModelClientResponse, any>({
        path: `/api/Products/ImportProductsFromWordPressFeed`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Search
     * @name SearchSearchPostalCodeList
     * @request GET:/api/Search/SearchPostalCode
     * @secure
     */
    searchSearchPostalCodeList: (
      query?: {
        query?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PostalCodeSearchResponseClientResponse, any>({
        path: `/api/Search/SearchPostalCode`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Search
     * @name SearchSearchAddressesList
     * @request GET:/api/Search/SearchAddresses
     * @secure
     */
    searchSearchAddressesList: (
      query?: {
        query?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchAddressesResponseClientResponse, any>({
        path: `/api/Search/SearchAddresses`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Search
     * @name SearchGetPropertyInformationList
     * @request GET:/api/Search/GetPropertyInformation
     * @secure
     */
    searchGetPropertyInformationList: (
      query?: {
        addressId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PropertyInformationResponseClientResponse, any>({
        path: `/api/Search/GetPropertyInformation`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name ShopGetCartList
     * @request GET:/api/Shop/GetCart
     * @secure
     */
    shopGetCartList: (params: RequestParams = {}) =>
      this.request<CartViewObjectClientResponse, any>({
        path: `/api/Shop/GetCart`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name ShopClearCartCreate
     * @request POST:/api/Shop/ClearCart
     * @secure
     */
    shopClearCartCreate: (params: RequestParams = {}) =>
      this.request<CartViewObjectClientResponse, any>({
        path: `/api/Shop/ClearCart`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name ShopAddOrUpdateLineItemsCreate
     * @request POST:/api/Shop/AddOrUpdateLineItems
     * @secure
     */
    shopAddOrUpdateLineItemsCreate: (data: AddOrUpdateLineItemsRequest, params: RequestParams = {}) =>
      this.request<CartViewObjectClientResponse, any>({
        path: `/api/Shop/AddOrUpdateLineItems`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name ShopAddOrUpdateCartCreate
     * @request POST:/api/Shop/AddOrUpdateCart
     * @secure
     */
    shopAddOrUpdateCartCreate: (data: AddOrUpdateCartRequest, params: RequestParams = {}) =>
      this.request<CartViewObjectClientResponse, any>({
        path: `/api/Shop/AddOrUpdateCart`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name ShopReOrderCreate
     * @request POST:/api/Shop/ReOrder
     * @secure
     */
    shopReOrderCreate: (
      query: {
        /** @format uuid */
        orderId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderViewObjectClientResponse, any>({
        path: `/api/Shop/ReOrder`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name ShopSubmitOrderCreate
     * @request POST:/api/Shop/SubmitOrder
     * @secure
     */
    shopSubmitOrderCreate: (params: RequestParams = {}) =>
      this.request<OrderViewObjectClientResponse, any>({
        path: `/api/Shop/SubmitOrder`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name ShopSearchOrderHistoryCreate
     * @request POST:/api/Shop/SearchOrderHistory
     * @secure
     */
    shopSearchOrderHistoryCreate: (data: SearchOrderHistoryRequest, params: RequestParams = {}) =>
      this.request<PagedOrdersViewModelClientResponse, any>({
        path: `/api/Shop/SearchOrderHistory`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name ShopSearchOrdersCreate
     * @request POST:/api/Shop/SearchOrders
     * @secure
     */
    shopSearchOrdersCreate: (data: SearchOrdersRequest, params: RequestParams = {}) =>
      this.request<PagedOrdersViewModelClientResponse, any>({
        path: `/api/Shop/SearchOrders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Shop
     * @name ShopDeleteOrderDelete
     * @request DELETE:/api/Shop/DeleteOrder
     * @secure
     */
    shopDeleteOrderDelete: (
      query: {
        /** @format uuid */
        orderId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OrderViewObjectClientResponse, any>({
        path: `/api/Shop/DeleteOrder`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUsersCreate
     * @request POST:/api/Users/GetUsers
     * @secure
     */
    usersGetUsersCreate: (params: RequestParams = {}) =>
      this.request<UsersViewModelClientResponse, any>({
        path: `/api/Users/GetUsers`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetEmployeesCreate
     * @request POST:/api/Users/GetEmployees
     * @secure
     */
    usersGetEmployeesCreate: (params: RequestParams = {}) =>
      this.request<UsersViewModelClientResponse, any>({
        path: `/api/Users/GetEmployees`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUserByIdList
     * @request GET:/api/Users/GetUserById
     * @secure
     */
    usersGetUserByIdList: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/GetUserById`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUsersByIdCreate
     * @request POST:/api/Users/GetUsersById
     * @secure
     */
    usersGetUsersByIdCreate: (data: UsersByIdRequest, params: RequestParams = {}) =>
      this.request<UsersViewModelClientResponse, any>({
        path: `/api/Users/GetUsersById`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUpdateProfileImageUpdate
     * @request PUT:/api/Users/UpdateProfileImage
     * @secure
     */
    usersUpdateProfileImageUpdate: (
      data: {
        /** @format binary */
        formFile?: File;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/UpdateProfileImage`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetUserLoginHistoryCreate
     * @request POST:/api/Users/GetUserLoginHistory
     * @secure
     */
    usersGetUserLoginHistoryCreate: (params: RequestParams = {}) =>
      this.request<UserLoginHistoryViewModelClientResponse, any>({
        path: `/api/Users/GetUserLoginHistory`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersGetLoginHistoryForUserCreate
     * @request POST:/api/Users/GetLoginHistoryForUser
     * @secure
     */
    usersGetLoginHistoryForUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserLoginHistoryViewModelClientResponse, any>({
        path: `/api/Users/GetLoginHistoryForUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersCreateUserCreate
     * @request POST:/api/Users/CreateUser
     * @secure
     */
    usersCreateUserCreate: (data: CreateUserRequest | UpdateUserRequest, params: RequestParams = {}) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/CreateUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUpdateUserCreate
     * @request POST:/api/Users/UpdateUser
     * @secure
     */
    usersUpdateUserCreate: (data: UpdateUserRequest, params: RequestParams = {}) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/UpdateUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersDeleteUserCreate
     * @request POST:/api/Users/DeleteUser
     * @secure
     */
    usersDeleteUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/DeleteUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersRestoreUserCreate
     * @request POST:/api/Users/RestoreUser
     * @secure
     */
    usersRestoreUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/RestoreUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersBlockUserCreate
     * @request POST:/api/Users/BlockUser
     * @secure
     */
    usersBlockUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/BlockUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUnblockUserCreate
     * @request POST:/api/Users/UnblockUser
     * @secure
     */
    usersUnblockUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/UnblockUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUpdateAllowableIpsForUserCreate
     * @request POST:/api/Users/UpdateAllowableIpsForUser
     * @secure
     */
    usersUpdateAllowableIpsForUserCreate: (data: AddOrUpdateAllowableIpsRequest, params: RequestParams = {}) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/UpdateAllowableIpsForUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersResetPasswordForUserCreate
     * @request POST:/api/Users/ResetPasswordForUser
     * @secure
     */
    usersResetPasswordForUserCreate: (
      query: {
        /** @format uuid */
        userId: string;
        password?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/ResetPasswordForUser`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersResendWelcomeMailCreate
     * @request POST:/api/Users/ResendWelcomeMail
     * @secure
     */
    usersResendWelcomeMailCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/ResendWelcomeMail`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersResendWelcomeSmsCreate
     * @request POST:/api/Users/ResendWelcomeSms
     * @secure
     */
    usersResendWelcomeSmsCreate: (
      query: {
        /** @format uuid */
        userId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/ResendWelcomeSms`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUpdateCustomerAccessForUserCreate
     * @request POST:/api/Users/UpdateCustomerAccessForUser
     * @secure
     */
    usersUpdateCustomerAccessForUserCreate: (data: UpdateCustomerAccessRequest, params: RequestParams = {}) =>
      this.request<UserViewModelClientResponse, any>({
        path: `/api/Users/UpdateCustomerAccessForUser`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersUpdateCustomerAccessForUsersCreate
     * @request POST:/api/Users/UpdateCustomerAccessForUsers
     * @secure
     */
    usersUpdateCustomerAccessForUsersCreate: (data: UpdateCustomerAccessesRequest, params: RequestParams = {}) =>
      this.request<UsersViewModelClientResponse, any>({
        path: `/api/Users/UpdateCustomerAccessForUsers`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
